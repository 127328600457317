import {conform, useForm} from '@conform-to/react'
import {getFieldsetConstraint, parse} from '@conform-to/zod'
import {type ActionFunctionArgs, json, type LoaderFunctionArgs, type MetaFunction, redirect} from '@remix-run/node'
import {Form, Link, useActionData, useSearchParams} from '@remix-run/react'
import {AuthenticityTokenInput} from 'remix-utils/csrf/react'
import {HoneypotInputs} from 'remix-utils/honeypot/react'
import {safeRedirect} from 'remix-utils/safe-redirect'
import {z} from 'zod'
import {GeneralErrorBoundary} from '#app/components/error-boundary.tsx'
import {CheckboxField, ErrorList, Field} from '#app/components/forms.tsx'
import {Spacer} from '#app/components/spacer.tsx'
import {StatusButton} from '#app/components/ui/status-button.tsx'
import {login, requireAnonymous, sessionKey} from '#app/utils/auth.server.ts'
import {validateCSRF} from '#app/utils/csrf.server.ts'
import {checkHoneypot} from '#app/utils/honeypot.server.ts'
import {combineResponseInits, useIsPending} from '#app/utils/misc.tsx'
import {authSessionStorage} from '#app/utils/session.server.ts'
import {EmailSchema, PasswordSchema} from '#app/utils/user-validation.ts'

const LoginFormSchema = z.object({
    username: EmailSchema,
    password: PasswordSchema,
    redirectTo: z.string().optional(),
    remember: z.boolean().optional(),
})

export async function handleNewSession(
    {
        request,
        session,
        remember,
        challenge,
        cognitoSession
    }: {
        request: Request
        session: { userId: string; id: string; expirationDate: Date }
        remember: boolean
        challenge: string
        cognitoSession: string
    },
    responseInit?: ResponseInit,
) {

    const authSession = await authSessionStorage.getSession(request.headers.get('cookie'))
    authSession.set(sessionKey, session.id)

    if ('NEW_PASSWORD_REQUIRED' === challenge) {
        return redirect(
            safeRedirect(`/change-password`),
            combineResponseInits(
                {
                    headers: {
                        'set-cookie': await authSessionStorage.commitSession(authSession, {
                            expires: remember ? session.expirationDate : undefined,
                        }),
                    },
                },
                responseInit,
            ),
        )
    }

    if ('MFA_SETUP' === challenge) {
        return redirect(
            safeRedirect(`/mfa-setup`),
            combineResponseInits(
                {
                    headers: {
                        'set-cookie': await authSessionStorage.commitSession(authSession, {
                            expires: remember ? session.expirationDate : undefined,
                        }),
                    },
                },
                responseInit,
            ),
        )
    }

    if ('SOFTWARE_TOKEN_MFA' === challenge) {
        return redirect(
            safeRedirect(`/mfa-challenge?session=${cognitoSession}`),
            combineResponseInits(
                {
                    headers: {
                        'set-cookie': await authSessionStorage.commitSession(authSession, {
                            expires: remember ? session.expirationDate : undefined,
                        }),
                    },
                },
                responseInit,
            ),
        )
    }

    return redirect(
        safeRedirect(`/dashboard`),
        combineResponseInits(
            {
                headers: {
                    'set-cookie': await authSessionStorage.commitSession(authSession, {
                        expires: remember ? session.expirationDate : undefined,
                    }),
                },
            },
            responseInit,
        ),
    )

}



export async function loader({request}: LoaderFunctionArgs) {
    await requireAnonymous(request)
    return json({})
}

export async function action({request}: ActionFunctionArgs) {
    // Get user id from session if it exists else redirect to home page
    await requireAnonymous(request)
    const formData = await request.formData()
    // csrf stand for Cross-Site Request Forgery. This involves saving and verifying a token saved in browser cookie
    await validateCSRF(formData, request.headers)
    // Honeypot is a simple technique to prevent spam bots from submitting forms. It works by adding a hidden field to the form that bots will fill, but humans won't.
    checkHoneypot(formData)

    const submission = await parse(formData, {
        schema: intent =>
            LoginFormSchema.transform(async (data, ctx) => {
                if (intent !== 'submit') return {...data, session: null, user: null, challenge: null}
                const response = await login(request, data.username, data.password)
                if (!response) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: 'Invalid username or password',
                    })
                    return z.NEVER
                }
                const {session, user, challenge} = response
                return {...data, session, user, challenge}
            }),
        async: true,
    })
    // get the password off the payload that's sent back
    delete submission.payload.password

    if (submission.intent !== 'submit') {
        // @ts-expect-error - conform should probably have support for doing this
        delete submission.value?.password
        return json({status: 'idle', submission} as const)
    }

    if (!submission.value?.session) {
        return json({status: 'error', submission} as const, {status: 400})
    }

    const {session, remember} = submission.value

    return handleNewSession({
        request,
        session: session,
        remember: remember ?? false,
        challenge: submission.value?.challenge?.type ?? '',
        cognitoSession: submission.value?.challenge?.cognitoSession ?? ''
    })

}

export default function LoginPage() {
    const actionData = useActionData<typeof action>()
    const isPending = useIsPending()
    const [searchParams] = useSearchParams()
    const redirectTo = searchParams.get('redirectTo')

    const [form, fields] = useForm({
        id: 'login-form',
        constraint: getFieldsetConstraint(LoginFormSchema),
        defaultValue: {redirectTo},
        lastSubmission: actionData?.submission,
        onValidate({formData}) {
            return parse(formData, {schema: LoginFormSchema})
        },
        shouldRevalidate: 'onBlur',
    })

    return (
        <div className="flex min-h-full flex-col justify-center pb-32 pt-10">
            <div className="mx-auto w-full max-w-md">
                <div className="flex flex-col gap-3 text-center">
                    <h2 className="text-h2">Welcome back!</h2>
                    <p className="text-body-md text-muted-foreground">Please enter your credentials.</p>
                </div>
                <Spacer size="xs"/>
                <div>
                    <div className="mx-auto w-full max-w-md px-8">
                        <Form method="POST" {...form.props}>
                            <AuthenticityTokenInput/>
                            <HoneypotInputs/>
                            <Field
                                labelProps={{children: 'email'}}
                                inputProps={{
                                    ...conform.input(fields.username),
                                    autoFocus: true,
                                    className: 'lowercase',
                                    autoComplete: 'username',
                                }}
                                errors={fields.username.errors}
                            />
                            <Field
                                labelProps={{children: 'password'}}
                                inputProps={{
                                    ...conform.input(fields.password, {
                                        type: 'password',
                                    }),
                                    autoComplete: 'current-password',
                                }}
                                errors={fields.password.errors}
                            />

                            <div className="flex justify-between">
                                <CheckboxField
                                    labelProps={{
                                        htmlFor: fields.remember.id,
                                        children: 'remember me',
                                    }}
                                    buttonProps={conform.input(fields.remember, {
                                        type: 'checkbox',
                                    })}
                                    errors={fields.remember.errors}
                                    labelClassName="text-black dark:text-white text-sm"
                                />
                                <div>
                                    <Link to="/forgot-password" className="text-body-xs font-semibold">
                                        forgot password?
                                    </Link>
                                </div>
                            </div>

                            <input {...conform.input(fields.redirectTo, {type: 'hidden'})} />
                            <ErrorList errors={form.errors} id={form.errorId}/>
                            <div className="flex items-center justify-between gap-6 pt-3">
                                <StatusButton className="w-full"
                                              status={isPending ? 'pending' : actionData?.status ?? 'idle'}
                                              type="submit" disabled={isPending}>
                                    Log In
                                </StatusButton>
                            </div>
                        </Form>
                        <div className="flex items-center justify-center gap-2 pt-6">
                            <span className="text-muted-foreground">New here?</span>
                            <Link to={redirectTo ? `/signup?${encodeURIComponent(redirectTo)}` : '/signup'}>create an
                                account</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const meta: MetaFunction = () => {
    return [{title: 'login to refundr'}]
}

export function ErrorBoundary() {
    return <GeneralErrorBoundary/>
}
